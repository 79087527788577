<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view
      v-else-if="!subPermission.store || !subPermission.update"
    />
    <b-card :title="$t('g.basicInformations')" v-else>
      <!-- form -->
      <validation-observer ref="form">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group label-for="client" :label="$t('g.client')">
                <validation-provider
                  #default="{ errors }"
                  name="client"
                  rules="required"
                >
                  <v-select
                    v-model="form_data.client_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="options_client"
                    :reduce="(option) => option.id"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                :label="$t('g.serviceType')"
                label-for="serviceType"
              >
                <validation-provider
                  #default="{ errors }"
                  name="serviceType"
                  rules="required"
                >
                  <v-select
                    v-model="form_data.service_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="options_service"
                    :reduce="(option) => option.id"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group :label="$t('g.sampleType')" label-for="sampleType">
                <validation-provider
                  #default="{ errors }"
                  name="sampleType"
                  rules="required"
                >
                  <v-select
                    v-model="form_data.sample_type_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :reduce="(option) => option.id"
                    label="title"
                    :options="options_sample_type"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group :label="$t('g.category')" label-for="category">
                <validation-provider
                  #default="{ errors }"
                  name="category"
                  rules="required"
                >
                  <v-select
                    v-model="form_data.category_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="options_category"
                    :disabled="isEdit"
                    :reduce="(option) => option.id"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('g.receiveDate')"
                label-for="receiveDate"
              >
                <validation-provider
                  #default="{ errors }"
                  name="receiveDate"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form_data.receive_date"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('g.excpectedReviewEndDate')"
                label-for="excpectedReviewEndDate"
              >
                <validation-provider
                  #default="{ errors }"
                  name="excpectedReviewEndDate"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form_data.excpected_review_end_date"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <!-- basic -->
              <b-form-group
                :label="$t('g.productName')"
                label-for="productName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="product name"
                  rules="required"
                >
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.prodcut_name"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Documents and regulations -->
          <b-row>
            <b-col xs="12" class="mb-1">
              <h4>{{ $t("g.documentsAndRegulations") }}</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group
                :label="$t('g.certRequest')"
                label-for="certRequest"
              >
                <validation-provider
                  #default="{ errors }"
                  name="cert request"
                  rules="required"
                >
                  <b-form-file
                    :placeholder="$t('g.uploadYourFileHere')"
                    drop-placeholder="Drop file here..."
                    v-model="form_data.cert_request_doc"
                    :state="errors.length > 0 ? false : null"
                    accept=".doc,.docx,.pdf"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('g.shipping')" label-for="shipping">
                <validation-provider
                  #default="{ errors }"
                  name="shipping"
                  rules="required"
                >
                  <b-form-file
                    :placeholder="$t('g.uploadYourFileHere')"
                    drop-placeholder="Drop file here..."
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.shipping_doc"
                    accept=".doc,.docx,.pdf"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('g.factory')" label-for="factory">
                <validation-provider
                  #default="{ errors }"
                  name="factory"
                  rules="required"
                >
                  <b-form-file
                    :placeholder="$t('g.uploadYourFileHere')"
                    drop-placeholder="Drop file here..."
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.factory_doc"
                    accept=".doc,.docx,.pdf"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('g.supplying')" label-for="supplying">
                <validation-provider
                  #default="{ errors }"
                  name="supplying"
                  rules="required"
                >
                  <b-form-file
                    :placeholder="$t('g.uploadYourFileHere')"
                    drop-placeholder="Drop file here..."
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.supplying_doc"
                    accept=".doc,.docx,.pdf"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-1">
              <b-form-group
                :label="$t('g.commercialRecord')"
                label-for="commercialRecord"
              >
                <validation-provider
                  #default="{ errors }"
                  name="commercial record"
                  rules="required"
                >
                  <b-form-file
                    :placeholder="$t('g.uploadYourFileHere')"
                    drop-placeholder="Drop file here..."
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.commercial_record_doc"
                    accept=".doc,.docx,.pdf"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('g.attachments')" label-for="file">
                <validation-provider
                  #default="{ errors }"
                  name="attachments"
                  rules="required"
                >
                  <b-form-file
                    :placeholder="$t('g.uploadYourFileHere')"
                    drop-placeholder="Drop file here..."
                    v-model="form_data.attachments"
                    accept=".doc,.docx,.pdf"
                    :disabled="isEdit"
                    :state="errors.length > 0 ? false : null"
                    multiple
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- </div> -->
          <b-button
            :variant="isEdit ? 'success' : 'primary'"
            type="submit"
            @click.prevent="validationForm"
          >
            {{ isEdit ? $t("g.edit") : $t("g.submit") }}
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormFile,
  BCard,
  BForm,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { required, confirmed, min } from "@validations";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import store from "@/store/index";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
    BFormFile,
    flatPickr,
    vSelect,
    BCard,
    Loading,
    Error,
    ContentNotView,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      form_data: {
        sample_type_id: null,
        service_id: null,
        category_id: null,
        client_id: null,
        prodcut_name: null,
        receive_date: null,
        cert_request_doc: null,
        shipping_doc: null,
        factory_doc: null,
        supplying_doc: null,
        commercial_record_doc: null,
        excpected_review_end_date: null,
        attachments: [],
      },
      isLoading: true,
      isEdit: false,
      isError: false,
      currentId: null,
      options_client: [],
      options_sample_type: [],
      options_service: [],
      options_category: [],
      subPermission: {},
    };
  },

  watch: {
    $route(to, from) {
      if (to.name === "AddServiceRequest") {
        this.handleWatchRoutePage();
        console.clear();
      }
    },
  },

  created() {
    // this.getAllDataSelected();
    if (this.$route.params.id) {
      this.isEdit = true;
      this.currentId = this.$route.params.id;
      this.getServiceRequests(this.$route.params.id);
    }

    this.format = formatDate;
  },

  beforeMount() {
    this.getAllDataSelected();
  },

  methods: {
    getServiceRequests(id) {
      this.$http
        .get(`admin/serviceRequests/${id}`)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.data;
            // console.log(data);
            this.form_data.sample_type_id = data?.sample_type?.id;
            this.form_data.service_id = data?.service?.id;
            this.form_data.category_id = data?.category?.id;
            this.form_data.client_id = data?.client?.id;
            this.form_data.prodcut_name = data?.prodcut_name;
            this.form_data.receive_date = data?.receive_date;
            this.form_data.cert_request_doc = data?.cert_request_doc;
            this.form_data.shipping_doc = data?.shipping_doc;
            this.form_data.factory_doc = data?.factory_doc;
            this.form_data.supplying_doc = data?.supplying_doc;
            this.form_data.commercial_record_doc = data?.commercial_record_doc;
            this.form_data.attachments = data?.attachments;
            // this.pageStatus(false, false);
          }
        })
        .catch((error) => {
          console.log(error);
          this.pageStatus(false, true);
        });
    },

    mapData(data) {
      return data.map((item) => ({
        id: item.id,
        title: item.name,
      }));
    },

    getAllDataSelected() {
      // const endPoints = ["categories", "services", "clients"];
      const endPoint = ["categories", "services", "clients", "sampleTypes"];

      const results = endPoint.map((item) => this.$http.get(`admin/${item}`));

      Promise.all(results)
        .then((res) =>
          res.map((arr, index) => {
            const type = endPoint[index];
            switch (type) {
              case "categories":
                this.options_category = this.mapData(arr.data.data);
                break;
              case "services":
                this.options_service = this.mapData(arr.data.data);
                break;
              case "clients":
                this.options_client = this.mapData(arr.data.data);
                break;
              case "sampleTypes":
                this.options_sample_type = this.mapData(arr.data.data);
                break;
              default:
                break;
            }
            setTimeout(() => {
              this.pageStatus(false, false);
              store.dispatch("GET_PERMISSION", "serviceRequests");
              this.subPermission = store.state.permissions.sub;
            }, 1000);
          })
        )
        .catch((error) => {
          console.log(error), this.pageStatus(false, true);
        });
    },

    // Update page data when it changes
    handleWatchRoutePage() {
      this.isEdit = false;
      this.clearForm();
      this.$refs.form.reset();
    },
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    // Changes the state of the page according to the return of the data => ( success Or error )
    pageStatus(loading, error) {
      this.isLoading = loading;
      this.isError = error;
    },
    //  Clear Form Data
    clearForm() {
      let form = this.form_data;

      for (let i in form) {
        if (i !== "attachments") {
          form[i] = null;
        } else {
          form[i] = [];
        }
      }
    },

    // Send the modified data
    submitEdit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          // append data to FormData object before sending to backend
          let formData = new FormData();

          formData.append("prodcut_name", this.form_data.prodcut_name);

          this.$http
            .post(
              `admin/serviceRequests/${this.currentId}?_method=put`,
              formData
            )
            .then((res) => {
              this.makeToast(
                "success",
                this.$t("g.send.successBody"),
                this.$t("g.send.successTitle")
              );
              setTimeout(() => {
                this.$router.push({ name: "ListServiceRequests" });
              }, 500);
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    //  Submit new user
    submitNewAddition() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          // append data to FormData object before sending to backend
          let formData = new FormData();
          for (let item in this.form_data) {
            if (item !== "attachments") {
              formData.append(item, this.form_data[item]);
            }
          }

          for (let item in this.form_data.attachments) {
            formData.append("attachments", this.form_data.attachments[item]);
          }

          // call backend - $http is an axios prototype
          this.$http
            .post("admin/serviceRequests", formData)
            .then((res) => {
              this.makeToast(
                "success",
                this.$t("g.send.successBody"),
                this.$t("g.send.successTitle")
              );
              setTimeout(() => {
                this.$router.push({ name: "ListServiceRequests" });
              }, 1000);
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    //  Submit Function
    validationForm() {
      if (
        this.form_data.attachments === null ||
        this.form_data.attachments === []
      ) {
        delete this.form_data.attachments;
      }
      if (this.isEdit) {
        return this.submitEdit();
      }
      this.submitNewAddition();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

[dir] textarea.form-control {
  padding: 0.5rem 0.5rem !important;
}
</style>
