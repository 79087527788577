var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('loading'):(_vm.isError)?_c('error'):(!_vm.subPermission.store || !_vm.subPermission.update)?_c('content-not-view'):_c('b-card',{attrs:{"title":_vm.$t('g.basicInformations')}},[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"client","label":_vm.$t('g.client')}},[_c('validation-provider',{attrs:{"name":"client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.options_client,"reduce":function (option) { return option.id; },"disabled":_vm.isEdit},model:{value:(_vm.form_data.client_id),callback:function ($$v) {_vm.$set(_vm.form_data, "client_id", $$v)},expression:"form_data.client_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.serviceType'),"label-for":"serviceType"}},[_c('validation-provider',{attrs:{"name":"serviceType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.options_service,"reduce":function (option) { return option.id; },"disabled":_vm.isEdit},model:{value:(_vm.form_data.service_id),callback:function ($$v) {_vm.$set(_vm.form_data, "service_id", $$v)},expression:"form_data.service_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.sampleType'),"label-for":"sampleType"}},[_c('validation-provider',{attrs:{"name":"sampleType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"reduce":function (option) { return option.id; },"label":"title","options":_vm.options_sample_type,"disabled":_vm.isEdit},model:{value:(_vm.form_data.sample_type_id),callback:function ($$v) {_vm.$set(_vm.form_data, "sample_type_id", $$v)},expression:"form_data.sample_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.category'),"label-for":"category"}},[_c('validation-provider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.options_category,"disabled":_vm.isEdit,"reduce":function (option) { return option.id; }},model:{value:(_vm.form_data.category_id),callback:function ($$v) {_vm.$set(_vm.form_data, "category_id", $$v)},expression:"form_data.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.receiveDate'),"label-for":"receiveDate"}},[_c('validation-provider',{attrs:{"name":"receiveDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null,"disabled":_vm.isEdit},model:{value:(_vm.form_data.receive_date),callback:function ($$v) {_vm.$set(_vm.form_data, "receive_date", $$v)},expression:"form_data.receive_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.excpectedReviewEndDate'),"label-for":"excpectedReviewEndDate"}},[_c('validation-provider',{attrs:{"name":"excpectedReviewEndDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null,"disabled":_vm.isEdit},model:{value:(_vm.form_data.excpected_review_end_date),callback:function ($$v) {_vm.$set(_vm.form_data, "excpected_review_end_date", $$v)},expression:"form_data.excpected_review_end_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.productName'),"label-for":"productName"}},[_c('validation-provider',{attrs:{"name":"product name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.prodcut_name),callback:function ($$v) {_vm.$set(_vm.form_data, "prodcut_name", $$v)},expression:"form_data.prodcut_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"xs":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("g.documentsAndRegulations")))])])],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.certRequest'),"label-for":"certRequest"}},[_c('validation-provider',{attrs:{"name":"cert request","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('g.uploadYourFileHere'),"drop-placeholder":"Drop file here...","state":errors.length > 0 ? false : null,"accept":".doc,.docx,.pdf","disabled":_vm.isEdit},model:{value:(_vm.form_data.cert_request_doc),callback:function ($$v) {_vm.$set(_vm.form_data, "cert_request_doc", $$v)},expression:"form_data.cert_request_doc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.shipping'),"label-for":"shipping"}},[_c('validation-provider',{attrs:{"name":"shipping","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('g.uploadYourFileHere'),"drop-placeholder":"Drop file here...","state":errors.length > 0 ? false : null,"accept":".doc,.docx,.pdf","disabled":_vm.isEdit},model:{value:(_vm.form_data.shipping_doc),callback:function ($$v) {_vm.$set(_vm.form_data, "shipping_doc", $$v)},expression:"form_data.shipping_doc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.factory'),"label-for":"factory"}},[_c('validation-provider',{attrs:{"name":"factory","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('g.uploadYourFileHere'),"drop-placeholder":"Drop file here...","state":errors.length > 0 ? false : null,"accept":".doc,.docx,.pdf","disabled":_vm.isEdit},model:{value:(_vm.form_data.factory_doc),callback:function ($$v) {_vm.$set(_vm.form_data, "factory_doc", $$v)},expression:"form_data.factory_doc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.supplying'),"label-for":"supplying"}},[_c('validation-provider',{attrs:{"name":"supplying","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('g.uploadYourFileHere'),"drop-placeholder":"Drop file here...","state":errors.length > 0 ? false : null,"accept":".doc,.docx,.pdf","disabled":_vm.isEdit},model:{value:(_vm.form_data.supplying_doc),callback:function ($$v) {_vm.$set(_vm.form_data, "supplying_doc", $$v)},expression:"form_data.supplying_doc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.commercialRecord'),"label-for":"commercialRecord"}},[_c('validation-provider',{attrs:{"name":"commercial record","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('g.uploadYourFileHere'),"drop-placeholder":"Drop file here...","state":errors.length > 0 ? false : null,"accept":".doc,.docx,.pdf","disabled":_vm.isEdit},model:{value:(_vm.form_data.commercial_record_doc),callback:function ($$v) {_vm.$set(_vm.form_data, "commercial_record_doc", $$v)},expression:"form_data.commercial_record_doc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.attachments'),"label-for":"file"}},[_c('validation-provider',{attrs:{"name":"attachments","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('g.uploadYourFileHere'),"drop-placeholder":"Drop file here...","accept":".doc,.docx,.pdf","disabled":_vm.isEdit,"state":errors.length > 0 ? false : null,"multiple":""},model:{value:(_vm.form_data.attachments),callback:function ($$v) {_vm.$set(_vm.form_data, "attachments", $$v)},expression:"form_data.attachments"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)],1),_c('b-button',{attrs:{"variant":_vm.isEdit ? 'success' : 'primary',"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("g.edit") : _vm.$t("g.submit"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }